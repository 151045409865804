<template>
  <v-container>

    <h2 class="mb-1 blue--text text--darken-1">
      MIS SERVICIOS
    </h2>

    <loader v-if="isloading"></loader>

    <v-row dense>
      <v-col cols="12">
        <v-btn :to="`/crear-servicio`" rounded depressed :disabled="isloading" rounded depressed color="pink darken-1" class="white--text mr-3 mt-4">
          Crear servicio
        </v-btn>
      </v-col>
    </v-row>

    <v-row class="mt-4" dense>
      <v-col cols="12" md="6">
        <v-alert shaped dense outlined type="warning">
          Los servicios deben ser aprobados por administración
        </v-alert>
      </v-col>

      <v-col cols="12">
        <v-data-table :headers="headers" :items="services" disable-pagination hide-default-footer item-key="key" class="elevation-1">

          <template v-slot:item.calendario="{ item }">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on" @click="$emit('open_modal', item)" color="blue">
                  mdi-calendar
                </v-icon>
              </template>
              <span>Crear calendario</span>
            </v-tooltip>

            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <router-link :to="`/calendario-servicio?id=${item.id}`">
                  <v-icon v-bind="attrs" v-on="on" class="ml-2" color="green">
                    mdi-calendar-clock
                  </v-icon>
                </router-link>
              </template>
              <span>Ver calendario</span>
            </v-tooltip>

          </template>

          <template v-slot:item.online="{ item }">

            <v-chip @click="changeStatus(item)" class="white--text" :color="item.online ? 'green' : 'red lighten-1'" small dense>
              {{item.online ? 'disponible' : 'no disponible'}}
            </v-chip>
          </template>

          <template v-slot:item.action="{ item }">
            <router-link :to="`/crear-servicio?id=${item.id}`">
              <v-icon small class="mr-2" color="blue">
                mdi-pencil
              </v-icon>
            </router-link>

            <v-icon @click="openModal(item)" small color="red lighten-1">
              mdi-trash-can
            </v-icon>
          </template>

        </v-data-table>
      </v-col>
    </v-row>

    <v-confirm-dialog v-on:delete="deleteServicio">
    </v-confirm-dialog>

    <modal-form-calendario></modal-form-calendario>

  </v-container>
</template>

<script>
  import {
    services_service
  } from '~/services_service'

  import modalFormCalendario from './modalFormCalendario'

  export default {
    components: {
      modalFormCalendario
    },

    data() {
      return {
        item: {},
        services: [],
        headers: [{
            text: 'Nombre',
            value: 'name',
          },
          {
            text: 'Estado',
            value: 'state',
          },
          {
            text: 'Categoria',
            value: 'categoryId.name',
          },
          {
            text: 'Online',
            value: 'online',
            align: 'center'
          },
          {
            text: 'Calendario',
            value: 'calendario',
            align: 'center'
          },
          {
            text: 'Acciones',
            value: 'action',
            sortable: false
          },
        ],
      }
    },


    mounted() {
      this.getServiciosByUser()
    },

    methods: {
      getServiciosByUser() {
        services_service.get_user_services(this.user.id, true).then(res => {

          this.services = res.data
        }, res => {
          this.$toast.error('Error consultando servicios')
        })
      },

      changeStatus(item) {
        let data = {
          online: !item.online,
          packageId: item.packageId.id
        }

        services_service.update_online_service(item.id, data).then(res => {
          item.online = !item.online
          let index = this.services.indexOf(item)
          this.$set(this.services, index, item)
          this.$toast.sucs('Servicio actualizado con exito')
        }, res => {
          this.$toast.error('Error actualizando servicio')
        })
      },


      openModal(item) {
        this.item = item
        this.$emit('confirm_open')
      },

      deleteServicio() {
        services_service.delete_service(this.item).then(res => {
          this.$toast.sucs('Servicio eliminado con exito')
          this.services.splice(this.services.indexOf(this.item), 1)
        }, res => {
          this.$toast.error('Error consultando registro')
        })
      }
    },
    computed: {
      isloading() {
        return this.$store.getters.getloading
      },
      errors() {
        return this.$store.getters.geterrors
      },
      user() {
        return JSON.parse(localStorage.getItem('user'))
      }
    }
  }
</script>