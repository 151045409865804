<template>
  <v-container>

    <v-card>
      <v-toolbar flat color="blue accent-2" class="white--text">
        <v-btn to="/" icon text>
          <v-icon small class="white--text">mdi-home</v-icon>
        </v-btn>
        <v-toolbar-title>
          <h3 class="font-weight-light">Clientes potenciales</h3>
        </v-toolbar-title>
      </v-toolbar>

      <v-card-text>

        <loader v-if="isloading"></loader>

        <v-row dense>
          <v-col cols="12">
            <v-data-table :headers="headers" :items="items" disable-pagination hide-default-footer item-key="key" class="elevation-1 mt-6">

              <template v-slot:item.user_doc="{ item }">
                <span>{{ item.user_doc.name }} {{item.user_doc.last_name}}</span>
              </template>

              <template v-slot:item.image="{ item }">
                <router-link :to="`/chat?id=${item.id}`">
                  <v-avatar>
                    <img :src="item.user_doc.image.includes('uploads') ? `${$awsPath}/${item.user_doc.image}` : item.image">
                  </v-avatar>
                </router-link>
                <span class="ml-3">{{item.name}}</span>
              </template>

              <template v-slot:item.created_at="{ item }">
                <span>{{ item.created_at | format_date}}</span>
              </template>

              <template v-slot:item.chat="{ item }">
                <router-link :to="`/chat?id=${item.ownerId}`">
                  <v-icon color="green">mdi-chat-processing</v-icon>
                </router-link>
              </template>

            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
  import {
    lead_service
  } from '~/lead_service'

  import moment from 'moment'

  import "moment/locale/es"

  export default {

    data() {
      return {
        items: [],

        headers: [{
            text: '',
            value: 'image',
          }, {
            text: 'Usuario',
            value: 'user_doc',
          },
          /*{
            text: 'Último mensaje',
            value: 'created_at',
          }, */
          {
            text: 'Chat',
            value: 'chat',
          }
        ],
      }
    },


    created() {
      this.getUserLeads()
    },

    methods: {
      getUserLeads() {
        lead_service.get_user_leads().then(res => {
          this.items = res.data.leads
        }, res => {
          this.$toast.error('Error consultando posts')
        })
      },
      invertUser(item) {
        return {
          id: item._id,
          image: item.image.includes('uploads') ? `${$awsPath}/${item.image}` : item.image,
          name: item.name,
          created_at: item.createdAt
        }
      },

    },

    filters: {
      user_image() {

      },
      format_hour(hour_24_format) {
        return moment(`01-01-2023 ${hour_24_format}:00`).format('hh:mm a')
      },

      format_date(db_date) {
        return moment(db_date).format('dddd, D MMMM - Y hh:mm a')
      },
    },
    computed: {
      isloading() {
        return this.$store.getters.getloading
      },
      errors() {
        return this.$store.getters.geterrors
      },
      user() {
        return JSON.parse(localStorage.getItem('user'))
      }
    }
  }
</script>