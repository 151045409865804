import axios from 'axios'

export const ticket_service = {

  async search_tickets(filters) {
    return axios.post(`tickets-search`, filters)
  },

  async asignar_ticket(data) {
    return axios.put(`tickets/${data.id}`, data)
  },

  async enviar_mensaje(data) {
    return axios.put(`tickets/${data.id}`, data)
  },

  async cerrar_ticket(data) {
    return axios.put(`tickets/${data.id}`, data)
  },

}