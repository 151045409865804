import axios from 'axios'

export const account_service = {

  async get_accounts(data) {
    return axios.post(`accounts-all`, data)
  },

  async get_account(id) {
    return axios.get(`account/${id}`)
  },
}