<template id="">
  <v-container>

    <loader v-if="isloading"></loader>

    <v-row class="fill-height">

      <v-col>
        <v-sheet height="64">
          <v-toolbar flat>
            <v-btn outlined class="mr-4" color="green" @click="setToday">
              Mes actual
            </v-btn>
            <v-btn fab text small color="grey darken-2" @click="$refs.calendar.prev()">
              <v-icon small>
                mdi-chevron-left
              </v-icon>
            </v-btn>
            <v-btn fab text small color="grey darken-2" @click="$refs.calendar.next()">
              <v-icon small>
                mdi-chevron-right
              </v-icon>
            </v-btn>

            <v-toolbar-title>
              {{ query.month }} - {{query.year}}
            </v-toolbar-title>

            <v-spacer></v-spacer>
            <v-btn outlined class="mr-4" color="blue" @click="$emit('open_days', value)">
              añadir horario
            </v-btn>
          </v-toolbar>
        </v-sheet>

        <v-sheet height="600">
          <v-calendar @click:event="openDialog" locale="es" event-more ref="calendar" v-model="value" :weekdays="weekday" :type="type" :events="events" :event-overlap-mode="mode" :event-overlap-threshold="30" :event-color="getEventColor">
            <template v-slot:event="{ event }">
              <div class="pl-2">
                {{ event.name | format_hour  }}
              </div>
            </template>
          </v-calendar>
        </v-sheet>
      </v-col>
    </v-row>

    <dialog-hour v-on:remove_hour="removerHour"></dialog-hour>

    <dialog-days v-on:get_hours="getCalendar($route.query.id, query.year, query.month)"></dialog-days>

  </v-container>
</template>

<script>
  import {
    calendar_service
  } from '~/calendar_service'

  import moment from 'moment'

  import dialogHour from './dialogHour'
  import dialogDays from './dialogDays'

  export default {
    components: {
      dialogHour,
      dialogDays
    },
    data() {
      return {
        editing: false,
        items: {},
        tipo: null,
        tipos: ['AT', 'S'],
        type: 'month',
        mode: 'stack',
        weekday: [0, 1, 2, 3, 4, 5, 6],
        value: new Date().toISOString().substr(0, 10),
        events: [],
        inmutable_date: new Date().toISOString().substr(0, 10),
        query: {
          year: null,
          month: null
        }
      }
    },

    watch: {
      'value': {
        immediate: true,
        handler(n) {
          let d = this.extracDate(n)
          this.query.year = d.year
          this.query.month = d.month
          this.getCalendar(this.$route.query.id, d.year, d.month)
        }
      },
    },

    created() {
      //this.$route.query.id ? this.getCalendar(this.$route.query.id) : null
    },

    mounted() {
      this.$refs.calendar.checkChange()
    },

    methods: {
      removerHour(hour) {
        this.events.splice(this.events.indexOf(hour), 1)
      },

      openDialog(service_hour) {
        this.$emit('open_dialog', service_hour.event)
      },


      getCalendar(service_id, year, month) {
        let date = `${year}-${month}`
        calendar_service.get_calendar_by_service(service_id, date).then(res => {
          if (res.data) {
            this.setEvents(res.data)
          }
        }, res => {
          this.$toast.error('Error consultando calendario')
        })
      },

      setEvents(calendar_service) {

        let id = calendar_service.id

        this.items = calendar_service.serviceHours.map((item, i) => {
          return {
            calendarServiceId: id,
            initTime: item.initTime,
            currentDate: item.currentDate,
            id: item.id
          }
        })

        //let horas_flat = new_hours.flatMap(x => x)

        //this.items = horas_flat

        this.events = this.items.map(x => {
          return {
            calendar_service_id: x.calendarServiceId,
            id: x.id,
            name: `${x.initTime}`,
            start: `${x.currentDate} ${x.initTime}`,
            currentDate: x.currentDate,
            color: 'blue',
            timed: true,
          }
        })
      },

      setToday() {
        this.value = this.inmutable_date
      },

      extracDate(date) {
        return {
          month: date.substr(5, 2),
          year: date.substr(0, 4)
        }
      },

      getEventColor(event) {
        return event.status ? 'red' : 'blue'
      },
    },

    filters: {
      format_hour(hour_24_format) {
        return moment(`01-01-2023 ${hour_24_format}:00`).format('hh:mm a')
      },
      format_date(db_date) {
        return moment(db_date).format('ll')
      },
    },

    computed: {
      isloading() {
        return this.$store.getters.getloading
      },
      errors() {
        return this.$store.getters.geterrors
      },
      user() {
        return JSON.parse(localStorage.getItem('user'))
      }
    }
  }
</script>