import axios from 'axios'

export const directorio_service = {

  async get_mis_anuncios(user_id) {
    return axios.get(`adverts-directory-by-user/${user_id}`)
  },

  async get_anuncio(id) {
    return axios.get(`adverts-directory/${id}`)
  },

  async get_count() {
    return axios.get(`get-count-adverts-directory`)
  },

  async save_anuncio(data) {
    return axios.post(`adverts-directory`, data)
  },

  async update_anuncio(id, data) {
    return axios.put(`adverts-directory/${id}`, data)
  },

}