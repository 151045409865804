const modulo_categoria = {
  strict: false,

  state: {
    categoria: {
      id: null,
      name: null,
      name_es: null,
      name_en: null,
      name_pt: null,
      image: null,
      type: 'COMPANY_ROLE',
      description: null,
      description_es: null,
      description_en: null,
      description_pt: null,
      certificate: false,
      enableCertificate: false,
      enableDocument: false,
      enableIdCard: false,
    },
    nombre_categoria: null
  },

  getters: {
    get_nombre_categoria: state => state.nombre_categoria,

    get_categoria: state => state.categoria,
  },

  mutations: {
    set_nombre_categoria: (state, nombre) => state.nombre_categoria = nombre,

    set_categoria: (state, categoria) => state.categoria = categoria,

  },

  actions: {
    setNombreCategoria: (context, nombre) => context.commit('set_nombre_categoria', nombre),

    setCategoria: (context, categoria) => context.commit('set_categoria', categoria),

  }
}

export default modulo_categoria;