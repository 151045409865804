<template>
  <div class="">
    <v-app-bar app flat class="nav-t">

      <v-toolbar-title class="font-weight-medium text-uppercase grey--text text--darken-1">
        ¡Saludos, {{user.name}}!
      </v-toolbar-title>

      <v-spacer></v-spacer>

      <v-app-bar-nav-icon @click.stop="toggleDrawer"></v-app-bar-nav-icon>

      <v-btn to="/" icon>
        <v-icon>mdi-home</v-icon>
      </v-btn>

      <v-btn @click="setTheme" icon>
        <v-icon>mdi-invert-colors</v-icon>
      </v-btn>


      <v-menu offset-y v-model="isOpened">

        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" :color="notifications_length > 0 ? 'pink lighten-1' : 'gray'" icon>
            <v-icon>mdi-bell</v-icon>
          </v-btn>
        </template>

        <v-list v-if="notifications.length > 0">
          <v-list-item v-for="(item, index) in notifications" :key="index">

            <v-list-item-title v-if="item.payload">
              <router-link :to="`/chat?id=${item.payload.from}`">
                <v-avatar>
                  <img :src="item.payload.image.includes('uploads') ? `${$awsPath}/${item.payload.image}` : item.payload.image">
                </v-avatar>
              </router-link>
              {{item.payload.action}}
            </v-list-item-title>

            <v-list-item-title v-else>
              <router-link :to="`/chat?id=${item.data.from}`">
                <v-avatar>
                  <img :src="item.data.image.includes('uploads') ? `${$awsPath}/${item.data.image}` : item.data.image">
                </v-avatar>
              </router-link>
              {{item.data.action}}
            </v-list-item-title>

          </v-list-item>
        </v-list>

      </v-menu>

      <v-btn @click="doLogout" icon>
        <v-icon>mdi-login-variant</v-icon>
      </v-btn>

    </v-app-bar>
  </div>
</template>

<script>
  import auth from '../../auth/auth.js'
  export default {
    data() {
      return {
        isOpened: false,
        has_unread: false
      }
    },
    watch: {
      'isOpened'(n, o) {
        if (n) {
          this.$store.dispatch('setNotificacionsLenght', 0)
        }
      },

      /*notifications(n, o) {
        if (n.length > 0) {
          this.has_unread = true
        }
      }*/
    },
    mounted() {
      let dark = localStorage.getItem('dark') == 'true' ? true : false
      this.$vuetify.theme.dark = dark
    },

    methods: {
      toggleDrawer() {
        this.$emit('toggleDrawer')
      },

      doLogout() {
        auth.logout()
      },

      setTheme() {
        this.$vuetify.theme.dark = !this.$vuetify.theme.dark
        localStorage.setItem('dark', this.$vuetify.theme.dark)
      }
    },


    computed: {
      notifications() {
        return this.$store.getters.get_notifications
      },
      user() {
        return JSON.parse(localStorage.getItem('user'))
      },
      notifications_length() {
        return this.$store.getters.get_notifications_length
      }
    }
  }
</script>

<style scoped="true">
  .v-list {
    height: 300px;

    overflow-y: auto
  }
</style>