import axios from 'axios'

export const services_service = {

  async get_user_services(user_id, status) {
    return axios.get(`services-by-user/${user_id}/${status}`)
  },

  async get_service(service_id) {
    return axios.get(`service/${service_id}`)
  },

  async delete_service(item) {
    return axios.delete(`service/${item.id}`)
  },

  async save_user_service(data) {
    return axios.post(`service`, data)
  },

  async update_user_service(service) {
    return axios.put(`service/${service.id}`, service)
  },

  async update_online_service(service_id, status) {
    return axios.put(`service/${service_id}`, status)
  },
}