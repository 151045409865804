<template id="">
  <v-container>

    <loader v-if="isloading"></loader>

    <h2 class="mb-4 pink--text text--darken-1">{{selected_pack.title}}</h2>

    <v-row v-if="show_form" dense>
      <v-col cols="12" md="6">
        <stripe-element-payment ref="paymentRef" :pk="pk" :elements-options="elementsOptions" :confirm-params="confirmParams" />
        <v-btn @click="pay" depressed rounded class="mt-4 white--text" color="green">Pagar {{selected_pack.price}}$</v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import {
    StripeElementPayment
  } from '@vue-stripe/vue-stripe'

  import {
    check_booking
  } from '~helpers/check_booking'

  export default {
    components: {
      StripeElementPayment,
    },

    data() {
      return {
        //pk: 'pk_live_51M1a3FL7H3TGzFRCWXOLghqkeWj4Uti8wYYYP9cp0Qmcf1DBE3SehbVjr8BmoKHpqhhiVPPU2xoKLos6OEmvQpvk00G1cMIRQF',
        pk: 'pk_test_51M1a3FL7H3TGzFRCiOcWLNRR4VMTtty2uwT5EzCVpXc40IzV5BSzL3g3U9xbK8AkvtXa2gREcSI9znMaunK2SR9E00Apz7x2We',
        elementsOptions: {
          appearance: {
            theme: 'stripe',
          },
        },
        confirmParams: {
          return_url: `${window.location.origin}/compra-realizada`,
        },
        show_form: false,
      }
    },

    mounted() {
      this.buyPackage()
    },

    methods: {
      buyPackage() {
        if (!this.selected_pack.id) {
          return this.$router.push('/seleccionar-package')
        }
        this.axios.post(`booking-directory`, {
          packageDirectoryId: this.selected_pack.id,
          status: 'PENDING',
        }).then(res => {
          console.log(res.data)
          this.createIntent(res.data, this.selected_pack)
        }, res => {

        })
      },

      makeData(booking, selected_pack) {
        return {
          priceId: selected_pack.stripeId,
          bookingId: booking.id,
          userId: this.user.id,
          packageDirectoryId: booking.packageDirectoryId
        }
      },

      createIntent(booking, item) {
        let data = this.makeData(booking, item)
        this.axios.post(`/create-subscription`, data).then(res => {
          console.log(res.data)
          this.elementsOptions.clientSecret = res.data.clientSecret
          this.show_form = true
        }, res => {

        })
      },
      pay() {
        this.$refs.paymentRef.submit()
      },
    },

    computed: {
      selected_pack() {
        return this.$store.getters.get_selected_pack
      },
      isloading() {
        return this.$store.getters.getloading
      },
      user() {
        return JSON.parse(localStorage.getItem('user'))
      },
    }
  }
</script>