<template>
  <v-container>

    <loader v-if="isloading"></loader>

    <v-card>

      <v-toolbar flat color="blue accent-2" class="white--text">
        <v-btn to="/servicios" icon text>
          <v-icon small class="white--text">mdi-arrow-left</v-icon>
        </v-btn>
        <v-toolbar-title>
          <h3 class="font-weight-light">Crear / Editar Servicio</h3>
        </v-toolbar-title>
      </v-toolbar>

      <v-card-text>

        <v-form ref="form" v-model="valid" lazy-validation>
          <!-- estado y categoria -->
          <v-row class="mt-3">

            <v-col cols="12" md="4">
              <v-select v-model="servicio.state" small dense outlined hide-details :items="states" label="Estado" item-text="name" item-value="name"></v-select>
            </v-col>

            <v-col cols="12" md="4">
              <v-select v-model="servicio.categoryId" :rules="categoria_rule" small dense outlined hide-details :items="categorias" label="Categoria" item-text="name" item-value="id"></v-select>
            </v-col>

            <v-col cols="12" md="4">
              <v-select v-model="servicio.subCategoryId" :rules="subcategoria_rule" small dense outlined hide-details :items="sub_categorias" label="Subcategoria" item-text="name" item-value="id"></v-select>
            </v-col>

          </v-row>

          <v-row>
            <v-col cols="12" md="4">
              <v-text-field v-model="servicio.name" :rules="name_rule" small dense hide-details outlined label="Nombre servicio"></v-text-field>
            </v-col>

            <v-col cols="12" md="4">
              <v-text-field v-model="servicio.title" :rules="title_rule" small dense hide-details outlined label="Titulo servicio"></v-text-field>
            </v-col>

            <v-col cols="12" md="3">
              <v-text-field v-model="servicio.price" :rules="precio_rule" type="number" step="0.1" small dense hide-details outlined label="Precio servicio (usd)"></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12">
              <v-textarea v-model="servicio.description" :rules="description_rule" small dense outlined label="Descripción del servicio"></v-textarea>
            </v-col>
          </v-row>


          <v-row dense>
            <v-col cols="12">
              <v-file-input @change="fileChange" multiple small outlined dense accept="image/*" label="Imagenes"></v-file-input>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="4">
              <v-text-field v-model="servicio.phone" :rules="phone_rule" small dense outlined label="Teléfono"></v-text-field>
            </v-col>

            <v-col cols="12" md="4">
              <v-text-field v-model="servicio.zipcode" :rules="zipcode_rule" small dense outlined label="Código postal"></v-text-field>
            </v-col>
          </v-row>

          <v-row dense>
            <v-col cols="12" md="8">
              <v-text-field v-model="servicio.youtubeLink" :rules="youtube_rule" small dense outlined label="Link video"></v-text-field>
            </v-col>
          </v-row>

          <v-row dense>
            <v-col cols="12" md="8">
              <v-autocomplete v-model="servicio.language" :disabled="isUpdating" :items="flags" outlined chips color="blue-grey lighten-2" label="Idiomas" item-text="name" item-value="name" multiple>

                <template v-slot:selection="data">
                  <v-chip v-bind="data.attrs" :input-value="data.selected" close @click="data.select" @click:close="remove(data.item)">
                    <v-avatar left>
                      <v-img :src="`https://hatscripts.github.io/circle-flags/flags/${data.item}.svg`"></v-img>
                    </v-avatar>
                    {{data.item}}
                  </v-chip>
                </template>

                <template v-slot:item="data">
                  <template>
                    <v-list-item-avatar>
                      <img :src="`https://hatscripts.github.io/circle-flags/flags/${data.item}.svg`" width="48">
                    </v-list-item-avatar>
                  </template>
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>

          <v-row dense>

            <v-col cols="12" md="8">
              <v-combobox :rules="location_rule" @keyup="comboChange" outlined v-model="selected_place" small dense :search-input.sync="locationSearchText" item-text="description" item-value="placeId" label="Ubicación" dense
                :items="locationFoundItems">
              </v-combobox>
            </v-col>

            <v-col cols="12" md="8">
              <div style="height:350px" ref="map">

              </div>
            </v-col>
          </v-row>

          <v-row v-if="servicio.id">
            <v-col cols="12" md="8">
              <v-expansion-panels>
                <v-expansion-panel>
                  <v-expansion-panel-header color="pink" class="white--text">
                    <h4>EDITAR IMAGENES</h4>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-row class="mt-3">
                      <v-col v-for="(item, i) in servicio.uploads" cols="12" md="3" :key="i">
                        <v-card>
                          <v-img :src="`${$awsPath}/${item}`" height="125" class="grey darken-4"></v-img>
                          <v-icon small class="mt-2 mb-2 ml-2" @click="deleteImage(item)" medium color="red lighten-1">
                            mdi-trash-can
                          </v-icon>
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="6">
              <v-btn :loading="isloading" @click="updateOrCreate" rounded class="white--text" depressed color="pink darken-1">guardar</v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>

  </v-container>
</template>

<script>
  import {
    services_service
  } from '~/services_service'

  import {
    categoria_service
  } from '~/categoria_service'

  import {
    states_service
  } from '~/states_service'

  import {
    Loader
  } from "@googlemaps/js-api-loader"

  import {
    google_place_service
  } from '~utils/google_place_service'

  import {
    servicio_rules
  } from '../mixins/servicio_rules'


  export default {
    mixins: [servicio_rules],

    data() {
      return {
        autoUpdate: true,

        isUpdating: false,

        flags: [
          'es',
          'us',
          'br',
          'de',
          'fr',
          'it'
        ],

        google_loader: new Loader({
          apiKey: 'AIzaSyBM5gR8kKS8-xVr3lMyRnBpKQZObEPJIfE',
          version: 'weekly',
        }),

        geocoder: null,

        selected_place: {
          description: null
        },

        locationSearchText: null,

        found_items: [],

        includes_item: [],

        marker: null,

        map: null,

        places_service: null,

        states: [],

        categorias: [],

        sub_categorias: [],

        upload: new FormData(),

        images: [],

        servicio: {
          uploads: [],
          packageId: null,
          subCategoryId: null,
          categoryId: null,
          name: null,
          title: null,
          description: null,
          state: "All",
          time: "1 Hora",
          lat: -34.397,
          long: 150.644,
          youtubeLink: "",
          zipcode: null,
          online: true,
          phone: null,
          price: 0,
          office: null,
          notify: "email",
          timeZoneName: null,
          language: []
        }
      }
    },

    watch: {
      isUpdating(val) {
        if (val) {
          setTimeout(() => (this.isUpdating = false), 3000)
        }
      },
      'servicio.categoryId'(n, o) {
        this.getSubcategories(n)
      },

      'locationSearchText'(n, o) {
        if (!n) {
          return null
        }
        this.selected_place = n
        this.places_service.getPlacePredictions({
          input: n,
          types: ['geocode']
        }, this.displaySuggestions)
      },

      'selected_place'(n, o) {
        if (!n.place_id) {
          return null
        }
        this.servicio.office = n.description
        this.findPlaceId(n.place_id)
      }
    },

    created() {
      this.$route.query.id ? this.getService(this.$route.query.id) : null
      this.getCategories(this.user.role)
      this.getStates()
      this.servicio.timeZoneName = Intl.DateTimeFormat().resolvedOptions().timeZone
    },

    mounted() {
      this.google_loader.load().then(async () => {
        const {
          Map
        } = await google.maps.importLibrary('maps')

        const {
          Marker
        } = await google.maps.importLibrary('marker')

        const {
          AutocompleteService
        } = await google.maps.importLibrary('places')

        const {
          Geocoder
        } = await google.maps.importLibrary("geocoding")

        const default_position = {
          lat: this.servicio.lat,
          lng: this.servicio.long
        }

        this.map = new Map(this.$refs.map, {
          center: default_position,
          zoom: 12,
        })

        const options = {
          types: ['geocode', 'geometry'],
          fields: ["place_id", "geometry", "name", "formatted_address"],
          placeIdOnly: true
        }

        this.places_service = new AutocompleteService(null, options)

        this.geocoder = new Geocoder()

        this.marker = new Marker({
          position: default_position,
          map: this.map
        })

      })
    },

    methods: {
      remove(data) {
        const index = this.servicio.language.indexOf(data)
        if (index >= 0) this.servicio.language.splice(index, 1)
      },

      comboChange(data) {
        //console.log('data',data);
      },

      setMakerPosition(lat, lng) {
        this.marker.setPosition({
          lat: lat,
          lng: lng
        })
      },

      setMapPosition(lat, lng) {
        this.map.setCenter({
          lat: lat,
          lng: lng
        })
      },

      async findPlaceId(place_id) {
        let results = await google_place_service.findPlaceById(place_id)
        this.servicio.lat = results[0].geometry.location.lat()
        this.servicio.long = results[0].geometry.location.lng()
        this.setMapPosition(this.servicio.lat, this.servicio.long)
        this.setMakerPosition(this.servicio.lat, this.servicio.long)
      },

      displaySuggestions(predictions, status) {
        if (status !== google.maps.places.PlacesServiceStatus.OK) {
          return null
        }
        this.found_items = predictions
      },

      getStates() {
        states_service.get_states().then(res => {
          this.states = res.data
        }, res => {
          this.$toast.error('Error consultando estados')
        })
      },

      getCategories(role) {
        categoria_service.get_categorias(role).then(res => {
          this.categorias = res.data
        }, res => {
          this.$toast.error('Error consultando categorias')
        })
      },

      getSubcategories(categoria_id) {
        categoria_service.get_sub_categorias(categoria_id).then(res => {
          this.sub_categorias = res.data
        }, res => {
          this.$toast.error('Error consultando sub categorias')
        })
      },

      updateOrCreate() {
        this.servicio.lat = parseFloat(this.servicio.lat)
        this.servicio.long = parseFloat(this.servicio.long)

        if (!this.$refs.form.validate()) {
          return this.$toast.warn('Error de validando formulario')
        }
        this.servicio.id ? this.updateService() : this.saveService()
      },

      saveService() {
        this.servicio.packageId = this.user_pack.packageDirectoryId.id
        services_service.save_user_service(this.servicio).then(res => {
          this.uploadImages(res.data.id)
        }, res => {
          this.$toast.error('Error guardando servicio')
        })
      },
      updateService() {
        this.servicio.packageId = this.user_pack.packageDirectoryId.id
        services_service.update_user_service(this.servicio).then(res => {
          this.uploadImages(res.data.id)
        }, res => {
          this.$toast.error('Error guardando servicio')
        })
      },

      uploadImages(id) {
        if (this.images.length <= 0) {
          this.$toast.sucs('Tu servicio ha sido enviado para aprobación')
          this.$router.push('/servicios')
          return
        }
        this.axios.post(`/upload/services/${id}`, this.upload, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }).then(res => {
          this.$toast.sucs('Archivos cargados con exito!')
          this.$router.push('/servicios')
        }, res => {
          this.$toast.error('Error cargando archivos!')

        })
      },

      getService(id) {
        services_service.get_service(id).then(res => {
          this.transformObject(res.data)
        }, res => {
          this.$toast.error('Error consultando servicio, intente nuevamente')
        })
      },

      fileChange(files) {
        let archivos = event.target.files
        for (let archivo of archivos) {
          this.images.push(archivo)
          this.upload.append('img', archivo)
        }
      },

      transformObject(data) {
        data.categoryId = data.categoryId.id
        data.userId = data.userId.id
        data.subCategoryId = data.subCategoryId.id
        data.packageId = data.packageId.id
        this.locationSearchText = data.office
        this.setMapPosition(parseFloat(data.lat), parseFloat(data.long))
        this.setMakerPosition(parseFloat(data.lat), parseFloat(data.long))
        this.servicio = JSON.parse(JSON.stringify(data))
      },

      deleteImage(item) {
        this.servicio.uploads.splice(this.servicio.uploads.indexOf(item), 1)
      }
    },

    computed: {
      isloading() {
        return this.$store.getters.getloading
      },
      errors() {
        return this.$store.getters.geterrors
      },
      user() {
        return JSON.parse(localStorage.getItem('user'))
      },
      user_pack() {
        return this.$store.getters.getpackage
      },
      locationFoundItems() {
        return this.found_items
      }
    }
  }
</script>

<style media="screen">
  .v-avatar img {
    height: 36px !important;
    width: 36px !important;
  }
</style>