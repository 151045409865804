<template>
  <v-container style="height:75vh;">

    <v-btn @click="$router.back()" class="white--text mb-4" rounded depressed small dense color="pink darken-1">volver</v-btn>

    <v-row ref="chatContainer" class="overflow-y-auto blue-grey lighten-5  rounded-lg" style="height:60vh;" dense>

      <v-col v-for="mensaje in mensajes" :key="mensaje.id" cols="12">
        <v-card :class="[!setOwn(mensaje) ? 'float-left blue accent-1 grey--text text--darken-4' : 'float-right pink accent-2 grey--text text--darken-4']" class="pa-2 mt-2 elevation-1" max-width="700">
          <v-row dense>
            <v-col cols="12">
              <v-avatar>
                <img :src="mensaje.from.image.includes('uploads') ? `${$awsPath}/${mensaje.from.image}` : mensaje.from.image">
              </v-avatar>
              <span class="font-weight-medium"> {{mensaje.message}}</span>
            </v-col>
          </v-row>
        </v-card>

      </v-col>
    </v-row>

    <v-row dense align="center">
      <v-col dense cols="12">
        <v-textarea hide-details @keydown.enter.exact.prevent @keyup.enter.exact="enviarMensaje" v-model="mensaje.message" @click:append-outer="enviarMensaje" append-outer-icon="mdi-send" filled placeholder="Mensaje" rows="3" row-height="20"
          @click:append="msg_type = 'file'">
        </v-textarea>

      </v-col>
    </v-row>



  </v-container>

</template>


<script type="text/javascript">
  export default {

    name: "chat_component",

    data() {
      return {
        mensajes: [],
        mensaje: {
          from: null,
          to: null,
          message: null
        }
      }
    },

    watch: {
      '$route.query.id': {
        handler: function(id) {
          this.mensaje.to = id
          this.$socket.emit('chats', this.mensaje)
        },
        deep: true,
      }
    },

    updated() {
      this.$nextTick(() => this.scrollToEnd());
    },

    sockets: {
      chats: function(data) {
        this.mensajes = data.reverse()
      },

      chat: function(data) {
        if (data.from._id == this.$route.query.id || data.from._id == this.user.id) {
          this.mensajes.push(data)
        }
      },
    },

    mounted() {
      this.mensaje.from = this.user.id
      this.mensaje.to = this.$route.query.id
      this.$socket.emit('chats', this.mensaje)
    },

    methods: {
      scrollToEnd: function() {
        if (this.$refs.chatContainer && this.$refs.chatContainer.lastElementChild) {
          this.$refs.chatContainer.scrollTop = this.$refs.chatContainer.lastElementChild.offsetTop;
        }
      },
      enviarMensaje() {
        this.$socket.emit('chat', this.mensaje)
        this.mensaje.message = null
      },
      setOwn(mensaje) {
        return (this.user.id == mensaje.from._id) ? true : false
      }
    },

    computed: {
      isloading() {
        return this.$store.getters.getloading
      },
      errors() {
        return this.$store.getters.geterrors
      },
      user() {
        return JSON.parse(localStorage.getItem('user'))
      },
      categoria() {
        return this.$store.getters.get_categoria
      }
    }
  }
</script>