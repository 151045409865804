<template>

  <v-app>
    <v-content>
      <v-container class="fill-height inspire" fluid>

        <v-row align="center" justify="center">

          <v-col cols="12" md="6">

            <v-stepper v-model="e1">

              <v-toolbar flat color="pink" class="white--text">

                <v-toolbar-title class="flex text-center">
                  <h3 class="font-weight-light">Recuperar contraseña</h3>
                </v-toolbar-title>
              </v-toolbar>

              <v-stepper-header>
                <v-stepper-step color="pink" :complete="e1 > 1" step="1">
                  Valida tus datos
                </v-stepper-step>

                <v-divider></v-divider>

                <v-stepper-step color="pink" :complete="e1 > 2" step="2">
                  Verificar código
                </v-stepper-step>

                <v-divider></v-divider>

                <v-stepper-step color="pink" step="3">
                  Reestablece tu contraseña
                </v-stepper-step>
              </v-stepper-header>

              <v-stepper-items>
                <v-stepper-content step="1">

                  <v-card class="pa-4 elevation-0">

                    <v-card-text class="pa-1 elevation-0">
                      <v-form>

                        <v-row dense>
                          <v-col v-if="active.type == 'email'" cols="12">
                            <v-text-field v-model="active.email" hide-details prepend-inner-icon="mdi-email-outline" dense outlined label="Email"></v-text-field>
                          </v-col>
                          <v-col v-else cols="12">
                            <v-text-field v-model="active.sms" hide-details prepend-inner-icon="mdi-phone" type="text" dense outlined label="Télefono"></v-text-field>
                          </v-col>
                        </v-row>

                        <v-row dense>
                          <v-radio-group class="pl-1" hide-details v-model="active.type" column>
                            <v-radio label="SMS" value="sms"></v-radio>
                            <v-radio label="Email" value="email"></v-radio>
                          </v-radio-group>
                        </v-row>


                      </v-form>
                    </v-card-text>


                    <v-card-actions class="mt-3">

                      <v-btn rounded depressed :disabled="isloading" @click="getCode" color="green" class="white--text" :loading="isloading">
                        Obtener Código
                      </v-btn>

                    </v-card-actions>
                  </v-card>

                </v-stepper-content>

                <v-stepper-content step="2">
                  <v-card class="pa-4 elevation-0">

                    <v-card-text class="pa-1 elevation-0">
                      <v-form>

                        <v-row dense>
                          <v-col cols="12">
                            <v-text-field v-model="active.code" hide-details prepend-inner-icon="mdi-send" type="text" dense outlined label="Código"></v-text-field>
                          </v-col>
                        </v-row>


                      </v-form>
                    </v-card-text>


                    <v-card-actions class="mt-3">

                      <v-btn rounded depressed :disabled="isloading" @click="e1 = 1" color="red lighten-1" class="white--text" :loading="isloading">
                        Volver
                      </v-btn>

                      <v-btn depressed rounded :disabled="isloading || active.code == null" @click="verificarCodigo" color="green" class="white--text" :loading="isloading">
                        Verificar Código
                      </v-btn>

                    </v-card-actions>
                  </v-card>
                </v-stepper-content>

                <v-stepper-content step="3">
                  <v-card class="pa-1 elevation-0">

                    <v-card-text class="pa-1">
                      <v-form>


                        <v-row dense>
                          <v-col cols="12" md="6">
                            <v-text-field v-model="user.password" outlined dense @click:prepend="show1 = !show1" :prepend-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'" small placeholder="Contraseña" required :type="show1 ? 'text' : 'password'">
                            </v-text-field>
                          </v-col>

                          <v-col cols="12" md="6">
                            <v-text-field v-model="user.passwordConfirmation" outlined dense @click:prepend="show2 = !show2" :prepend-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'" small placeholder="Confirmacion" required
                              :type="show2 ? 'text' : 'password'">
                            </v-text-field>
                          </v-col>

                        </v-row>

                      </v-form>
                    </v-card-text>


                    <v-card-actions class="mt-3">

                      <v-btn rounded depressed :disabled="isloading" @click="e1 = 1" color="red lighten-1" class="white--text" :loading="isloading">
                        Volver
                      </v-btn>

                      <v-btn depressed rounded :disabled="isloading" @click="signup" color="green" class="white--text" :loading="isloading">
                        Restaurar contraseña
                      </v-btn>

                    </v-card-actions>
                  </v-card>
                </v-stepper-content>
              </v-stepper-items>
            </v-stepper>

          </v-col>


        </v-row>
      </v-container>
    </v-content>
  </v-app>


</template>

<script>
  import auth from '../../auth/auth.js'
  export default {
    data() {
      return {
        token: null,
        e1: 1,
        active: {
          type: 'email',
          email: null,
          sms: null,
          validate: false,
          code: null,
          forgor_password: true
        },
        show1: false,
        show2: false,
        user: {
          password: null,
          passwordConfirmation: null,
          role: 'CONSULTANT_ROLE',
        }
      }
    },
    methods: {
      signup() {
        this.axios.put(`change-password/account/token`, this.user, {
          headers: {
            'x-access-token': this.token,
          }
        }).then(res => {
          this.$toast.sucs('Contraseña restaurada con exito')
          setTimeout(() => {
            this.$router.push('/login')
          }, 1500)
        }, res => {
          if (res.response.status == 400) {
            return this.$toast.error('Usuario ya se encuentra registrado')
          }
          this.$toast.error('Error registrando usuario')
        })
      },

      getCode() {
        const value = this.active.type == 'email' ? this.active.email : this.active.sms
        this.axios.post(`get_code/${value}`, this.active).then(res => {
          this.active.validate = true
          this.$toast.sucs('Código enviado con exito')
          this.e1 = 2
        }, res => {
          this.$toast.error('Error obteniendo código')
        })
      },

      verificarCodigo() {
        const value = this.active.type == 'email' ? this.active.email : this.active.sms
        this.axios.post(`get_code/${value}`, this.active).then(res => {

          if (res.data.ok == 'false' || res.data.ok == false) {
            return this.$toast.error('Error Validando código')
          }
          this.e1 = 3

          this.user.activated = true

          this.token = res.data.token

          this.active.type == 'email' ? this.user.email = value : this.user.phone = value

          this.$toast.sucs('Código validado con exito')
        }, res => {
          this.$toast.error('Error Validando código')
        })
      },
      onFileChange(file) {

      }
    },
    computed: {
      isloading: function() {
        return this.$store.getters.getloading
      },
      login_errors: function() {
        return this.$store.getters.get_login_errors
      },
    }
  }
</script>

<style>
  .inspire {
    /*background: rgb(5, 79, 200);
    background: linear-gradient(90deg, rgba(5, 79, 200, 1) 0%, rgba(27, 115, 244, 1) 100%);
    */
  }

  .cover-img {
    height: 100%
  }

  .back {
    background-color: #212121;
  }
</style>