import ListaLeads from '../componentes/ListaLeads'

const routes = [
  ...route('/lista-clientes-potenciales', ListaLeads, {
    auth: true,
  }),
]

function route(path, component = Default, meta = {}, props = true) {
  return [{
    path,
    component,
    meta,
    props
  }]
}

export default routes