import ListaDirectorio from '../componentes/ListaDirectorio'
import FormDirectorio from '../componentes/FormDirectorio'

const routes = [
  ...route('/mis-anuncios', ListaDirectorio, {
    auth: true,
  }),
  ...route('/guardar-anuncio-directorio', FormDirectorio, {
    auth: true,
  }),
]

function route(path, component = Default, meta = {}) {
  return [{
    path,
    component,
    meta
  }]
}

export default routes